/* eslint-disable import/prefer-default-export */
export enum currencyEnum {
  gold,
  diamond
}

export enum notificationType {
  GIFT,
  GIFT_BOOSTER,
  COMMENT,
  MESSAGE,
  RATE,
  INVITE_CLAN,
  KICK_CLAN,
  BONUS_CLAN,
  GIFT_PREMIUM,
  LUCKY_STATUS,
  CLAN,
  ALL
}

export enum UserRole {
  SUPER_ADMIN,
  ADMIN,
  MODERATOR,
  TESTER,
  USER,
  BOSS,
  C
}

export enum ClanModalSections {
  ALL,
  MY,
  SINGLE,
  TOP
}

export enum PrivateMessage {
  TEXT,
  VOICE,
  IMAGE,
  GIF
}

export enum ClanSearchSequenceBy {
  TITLE,
  POINTS,
  MEMBERS,
  DAILY_POINTS,
  WEEKLY_POINTS,
  MONTHLY_POINTS,
}
